import React from 'react';
import ProductQuery from './ProductQuery';

function App() {
  return (
    <div className="App">
      <ProductQuery />
    </div>
  );
}

export default App;