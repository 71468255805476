// src/ProductQuery.js
import React, { Component } from 'react';
import { Input, Button, Card, Tag, Table, message, Spin, Row, Col, Typography, Tooltip } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import axios from 'axios';
import './ProductQuery.css'; // 我们将创建这个文件来存放自定义样式
import './fonts.css';

const { Search } = Input;
const { Title } = Typography;

// 修改 keywordConfig
const keywordConfig = {
  'Crucial|英睿达': { display: ['英睿达', '🥈'], color: 'blue' },
  '三星|Samsung': { display: ['三星', '🥇'], color: 'blue' },
  'YMTC|长存': { display: ['长江存储', '🥈'], color: 'blue' },
  'XF MTC': { display: ['镁光', '🥈'], color: 'blue' },
  '240HZ|240Hz': { display: ['高刷', '电竞240Hz'], color: 'blue' },
  '120Hz': { display: ['高刷', '电竞120Hz'], color: 'blue' },
  '144Hz': { display: ['高刷', '电竞144Hz'], color: 'blue' },
  '165Hz': { display: ['高刷', '电竞165Hz'], color: 'blue' },
  'sRGB100%|100%sRGB': { display: ['高色域'], color: 'blue' },
  'BOE|京东方': { display: ['京东方'], color: 'blue' },
  'SiC|碳化硅': { display: ['碳化硅充电器'], color: 'blue' },
  'TLC': { display: ['TLC 颗粒'], color: 'blue' },
  'QLC': { display: ['QLC 颗粒'], color: 'blue' },
  'MLC': { display: ['MLC 颗粒'], color: 'blue' },
  'IR|红外': { display: ['人脸识别'], color: 'blue' },
  'RZ608': { display: ['AMD 网卡'], color: 'blue' },
  'AX200|AX201|AX210|AX211|AX410|AX411': { display: ['Intel网卡'], color: 'blue' },
  'R7-': { display: ['AMD R7'], color: 'blue' },
  'R9-': { display: ['AMD R9'], color: 'blue' },
  'DDR4|D4': { display: ['DDR4内存'], color: 'blue' },
  'DDR5|D5': { display: ['DDR5内存'], color: 'blue' },
  '2400': { display: ['2400MHz'], color: 'blue' },
  '2666': { display: ['2666MHz'], color: 'blue' },
  '2933': { display: ['2933MHz'], color: 'blue' },
  '3200': { display: ['3200MHz'], color: 'blue' },
  '3600': { display: ['3600MHz'], color: 'blue' },
  '3800': { display: ['3800MHz'], color: 'blue' },
  '4000': { display: ['4000MHz'], color: 'blue' },
  '4266': { display: ['4266MHz'], color: 'blue' },
  '4800': { display: ['4800MHz'], color: 'blue' },
  '5200': { display: ['5200MHz'], color: 'blue' },
  '5600': { display: ['5600MHz'], color: 'blue' },
  '5800': { display: ['5800MHz'], color: 'blue' },
  '6000': { display: ['6000MHz'], color: 'blue' },
  '6400': { display: ['6400MHz'], color: 'blue' },
  '6800': { display: ['6800MHz'], color: 'blue' },
  '7200': { display: ['7200MHz'], color: 'blue' },
  '7600': { display: ['7600MHz'], color: 'blue' },
  
  '4S1P': { display: ['4电芯串联'], color: 'blue' },
  '3S1P': { display: ['3电芯并联'], color: 'blue' },
  'AOHAI': { display: ['奥海电源'], color: 'blue' },

  // 可以根据需要添加更多关键字、显示内容和颜色
};

class ProductQuery extends Component {
  state = {
    loading: false,
    productData: null,
    lastQueryTime: 0,
    filteredMaterials: [],
    keyword: '',
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.productData !== prevState.productData && this.state.productData && this.state.productData.materials) {
      this.setState({ filteredMaterials: this.state.productData.materials });
    }
  }

  copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      message.success('已复制原始文本到剪贴板');
    });
  };

  calculateWarrantyDate = (productId) => {
    const year = parseInt(productId.substring(1, 3)) + 2000;
    const month = parseInt(productId.substring(3, 5));
    return `${year + 2}年${month}月`;
  };
  calculateOriDate = (productId) => {
    const year = parseInt(productId.substring(1, 3)) + 2000;
    const month = parseInt(productId.substring(3, 5));
    return `${year}年${month}月`;
  };

  handleSearch = async (value) => {
    // 首先尝试从 localStorage 获取数据
    const cachedData = localStorage.getItem(value);
    if (cachedData) {
      const parsedData = JSON.parse(cachedData);
      this.setState({ productData: parsedData, filteredMaterials: parsedData.materials });
      return;
    }

    // 如果本地没有缓存，则进行在线请求
    const currentTime = Date.now();
    if (currentTime - this.state.lastQueryTime < 60000) {
      message.error('请等待一分钟后再次在线查询');
      return;
    }

    this.setState({ loading: true, lastQueryTime: currentTime });

    try {
      const response = await axios.get(`/api/product/${value}`);
      if (response.data && response.data.product && response.data.materials) {
        this.setState({ productData: response.data, filteredMaterials: response.data.materials });
        // 将数据存储到 localStorage
        localStorage.setItem(value, JSON.stringify(response.data));
      } else {
        message.error('未找到产品信息');
      }
    } catch (error) {
      message.error('查询失败，请稍后重试');
    } finally {
      this.setState({ loading: false });
    }
  };

  handleKeywordSearch = (value) => {
    this.setState({ keyword: value });
    if (this.state.productData && this.state.productData.materials) {
      const filtered = this.state.productData.materials.filter(material => 
        Object.values(material).some(val => 
          val && val.toString().toLowerCase().includes(value.toLowerCase())
        )
      );
      this.setState({ filteredMaterials: filtered });
    }
  };

  renderLabelValue = (label, value) => (
    <Row gutter={[8, 8]} className="label-value-row">
      <Col xs={24} sm={8}>
        <Tag color="green" className="label-tag" style={{ fontFamily: "'JZFSSans-Regular', 'JZFSSans-Medium', sans-serif" }}>{label}:</Tag>
      </Col>
      <Col xs={24} sm={16}>
        <Tag color="blue" className="value-tag" style={{ fontFamily: "'JZFSSans-Regular', 'JZFSSans-Medium', sans-serif" }}>{value}</Tag>
      </Col>
    </Row>
  );

  // 修改 renderDescription 函数
  renderDescription = (text) => {
    const tags = [];
    Object.entries(keywordConfig).forEach(([keywords, config]) => {
      const keywordArray = keywords.split('|');
      const regex = new RegExp(keywordArray.map(keyword => 
        keyword.split('').join('\\s*')
      ).join('|'), 'i');
      
      if (regex.test(text)) {
        config.display.forEach((displayText, index) => {
          tags.push(
            <Tooltip key={`${keywords}-${index}`} title={`双击复制原文`}>
              <Tag 
                color={config.color} 
                style={{ 
                  cursor: 'pointer', 
                  marginRight: 4, 
                  fontFamily: "'JZFSSans-Regular', 'JZFSSans-Medium', sans-serif" 
                }}
              >
                {displayText}
              </Tag>
            </Tooltip>
          );
        });
      }
    });

    return (
      <div 
        className="copyable" 
        onDoubleClick={() => this.copyToClipboard(text)}
        style={{ cursor: 'pointer', fontFamily: "'JZFSSans-Regular', 'JZFSSans-Medium', sans-serif" }}
      >
        <div>{text}</div>
        {tags.length > 0 && <div style={{ marginTop: 4 }}>{tags}</div>}
      </div>
    );
  };

  render() {
    const columns = [
      {
        title: '类别',
        dataIndex: 'MATERIEL_TYPE_CODE',
        key: 'MATERIEL_TYPE_CODE',
        render: (text) => <span className="copyable" style={{ fontFamily: "'JZFSSans-Regular', 'JZFSSans-Medium', sans-serif" }} onClick={() => this.copyToClipboard(text)}>{text}</span>,
      },
      {
        title: '描述',
        dataIndex: 'MATERIEL_DESC',
        key: 'MATERIEL_DESC',
        render: this.renderDescription,
      },
      {
        title: '原始ID',
        dataIndex: 'MATERIEL_ID',
        key: 'MATERIEL_ID',
        render: (text) => <span className="copyable" style={{ fontFamily: "'JZFSSans-Regular', 'JZFSSans-Medium', sans-serif" }} onClick={() => this.copyToClipboard(text)}>{text}</span>,
      },
      {
        title: '新ID',
        dataIndex: 'NEW_MATERIAL_ID',
        key: 'NEW_MATERIAL_ID',
        render: (text) => <span className="copyable" style={{ fontFamily: "'JZFSSans-Regular', 'JZFSSans-Medium', sans-serif" }} onClick={() => this.copyToClipboard(text)}>{text}</span>,
      },
      {
        title: '用量',
        dataIndex: 'MATERIEL_COUNT',
        key: 'MATERIEL_COUNT',
        render: (text) => <span className="copyable" style={{ fontFamily: "'JZFSSans-Regular', 'JZFSSans-Medium', sans-serif" }} onClick={() => this.copyToClipboard(text)}>{text}</span>,
      },
    ];

    return (
      <div className={`product-query ${!this.state.productData ? 'centered' : ''}`} style={{ fontFamily: "'JZFSSans-Regular', 'JZFSSans-Medium', sans-serif" }}>
        <Search
          placeholder="请输入 产品 SN"
          enterButton={<Button icon={<SearchOutlined />}>查询</Button>}
          size="large"
          onSearch={this.handleSearch}
          className="search-input"
        />
        {this.state.loading && <Spin size="large" className="loading-spinner" />}
        {this.state.productData && (
          <Card className="result-card">
            <Title level={4} style={{ fontFamily: "'JZFSSans-Regular', 'JZFSSans-Medium', sans-serif" }}>产品信息</Title>
            {this.renderLabelValue('产品名称', this.state.productData.product.makeRoleName)}
            {this.renderLabelValue('主机 SN 编码', this.state.productData.product.beginProductId)}
            {this.renderLabelValue('物料ID', this.state.productData.product.makeMaterielId)}
            {this.renderLabelValue('量纲编号', this.state.productData.materials.find(m => m.REMARK)?.REMARK || '未提供')}
            {this.renderLabelValue('BOM 创建日期', this.state.productData.product.createDate)}
            {this.renderLabelValue('生产/购买日期', this.calculateOriDate(this.state.productData.product.beginProductId))}
            {this.renderLabelValue('有效质保期至', this.calculateWarrantyDate(this.state.productData.product.beginProductId))}
            {this.renderLabelValue('设备首次查询时间', this.state.productData.product.inputDate)}
            
            <Title level={4} className="materials-title" style={{ fontFamily: "'JZFSSans-Regular', 'JZFSSans-Medium', sans-serif" }}>物料信息</Title>
            <Search
              placeholder="输入关键词筛选物料"
              onSearch={this.handleKeywordSearch}
              className="keyword-search"
              style={{ fontFamily: "'JZFSSans-Regular', 'JZFSSans-Medium', sans-serif" }}
            />
            <Table 
              columns={columns} 
              dataSource={this.state.filteredMaterials} 
              rowKey="MATERIEL_ID"
              className="materials-table"
              scroll={{ x: true }}
              rowClassName={() => 'custom-table-row'}
            />
          </Card>
        )}
      </div>
    );
  }
}

export default ProductQuery;